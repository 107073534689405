<template>
  <div v-if="!isGetCollectionRequestPending" class="report-wrapper">
    <template v-if="collection && collection.length">
      <div class="header">
        <hub-checkbox :value="includeEarlier" label="Show all status changes" @change="changeIncludeEarlier" />
      </div>
      <div v-for="entry in sortedCollection" :key="entry._id">
        <div class="row" @click="toggleExpanded(entry._id)">
          <hub-icon :name="expanded.includes(entry._id) ? 'chevron-up' : 'chevron-down'" />
          <span v-if="entry._id === 'created_milestones'"> Milestone{{ entry.tasks.length > 1 ? 's' : '' }} created ({{ entry.tasks.length }})</span>
          <span v-else-if="entry._id === 'created_tasks'"> Task{{ entry.tasks.length > 1 ? 's' : '' }} created ({{ entry.tasks.length }})</span>
          <span v-else>
            <b>{{ entry._id }}</b> ({{ entry.tasks.length }})
          </span>
        </div>
        <div v-if="expanded.includes(entry._id)" class="expanded-report-row">
          <div v-for="task in entry.tasks" :key="task.id">
            <template v-if="entry._id === 'created_milestones'">
              <milestone-row :milestone="task" />
            </template>
            <template v-else> <task-row :task="task"/></template>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="loading-wrapper">
      No activity for today.
    </div>
  </div>
  <div v-else class="loading-wrapper">
    <hub-icon name="loading" spin size="lg"></hub-icon>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import Icon from '@/components/common/Icon';
import Checkbox from '@/components/common/Checkbox';
import MilestoneRow from './MilestoneRow.vue';
import TaskRow from './TaskRow.vue';

export default {
  components: {
    'hub-icon': Icon,
    'hub-checkbox': Checkbox,
    MilestoneRow,
    TaskRow
    // 'hub-button': Button,
  },
  data() {
    return {
      expanded: ['to do'],
      includeEarlier: false
    };
  },
  computed: {
    ...mapState({
      collection: s => s.reports.myTasksCount.collection,
      isGetCollectionRequestPending: s => s.reports.myTasksCount.isGetCollectionRequestPending
    }),
    sortedCollection() {
      const result = [];
      if (!this.collection) {
        return result;
      }
      const done = this.collection.find(item => item._id === 'done');
      const created_milestones = this.collection.find(item => item._id === 'created_milestones');
      const created_tasks = this.collection.find(item => item._id === 'created_tasks');

      const statuses = this.collection.filter(item => item._id !== 'done' && item._id !== 'created_milestones' && item._id !== 'created_tasks');
      if (done) {
        result.push(done);
      }
      result.push(...statuses.sort((a, b) => b.tasks.length - a.tasks.length));
      if (created_milestones) {
        result.push(created_milestones);
      }
      if (created_tasks) {
        result.push(created_tasks);
      }
      return result;
    }
  },
  created() {
    this.$store.dispatch('reports/myTasksCount/getCollection');
  },
  methods: {
    toggleExpanded(id) {
      const index = this.expanded.findIndex(i => i === id);
      if (index > -1) {
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(id);
      }
    },
    changeIncludeEarlier() {
      this.includeEarlier = !this.includeEarlier;
      this.$store.dispatch('reports/myTasksCount/getCollection', { includeEarlier: this.includeEarlier });
    }
  }
};
</script>
<style lang="scss" scoped>
.report-wrapper {
  height: 100%;
  overflow-y: scroll;
  padding: 0 15px;
  .header {
    padding: 15px 0;
  }
  .row {
    font-size: 1rem;
    padding: 15px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }
  .expanded-report-row {
    padding: 15px;
  }
}
.loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
