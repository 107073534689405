<template>
  <hub-report />
</template>

<script>
import Report from './../components/report/Report.vue';

export default {
  components: {
    'hub-report': Report
  },
  mounted() {}
};
</script>
