<template>
  <ul class="username-list">
    <li v-for="item of list" :key="item" class="username-list-item">{{ item }}</li>
  </ul>
</template>

<script>
function asUsernameCollection(collection) {
  if (!collection || !collection.length) {
    return '';
  }

  return collection.filter(a => a?.length).map(s => '@' + s.split('@')[0]);
}

export default {
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    list() {
      return Array.isArray(this.value) ? asUsernameCollection(this.value) : [];
    }
  }
};
</script>

<style lang="scss" scoped>
.username-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  .username-list-item {
    font-weight: 500;
    color: var(--theme-primary);
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>
